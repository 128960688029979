import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface NovedadesState {
  modalActivo: boolean;
  waitlistOverlaySeen: boolean;
  suspensionsOverlaySeen: boolean;
}

const novedadesSlice = createSlice({
  name: "novedades",
  initialState: {
    modalActivo: false,
    waitlistOverlaySeen: false,
    suspensionsOverlaySeen: false,
  } satisfies NovedadesState as NovedadesState,
  reducers: {
    activaModal(state) {
      state.modalActivo = true;
    },
    desactivaModal(state) {
      state.modalActivo = false;
    },
    setWaitlistOverlaySeen(state) {
      state.waitlistOverlaySeen = true;
    },
    setSuspensionsOverlaySeen(state) {
      state.suspensionsOverlaySeen = true;
    },
  },
});

export const {
  activaModal,
  desactivaModal,
  setWaitlistOverlaySeen,
  setSuspensionsOverlaySeen,
} = novedadesSlice.actions;
export const novedadesSelector = (state: RootState) => state.novedades;

export default novedadesSlice.reducer;
