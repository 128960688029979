import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import { parse } from "date-fns";
import { es } from "date-fns/locale";

import { GetAnswersResponse } from "api/client/models/GetAnswersResponse";
import { Respuesta } from "api/types/domain";
import { getTags } from "helpers/tags";
import { obtenerTagsCalculados } from "helpers/tagsCalculados";
import { normalizar } from "utils/normalize";

import { RootState } from ".";
import { CANAL_HEADER_NAME } from "./encuestas";

const resetPagination = (state: RespuestasState) => {
  state.indiceRespuestaSeleccionada = undefined;
  state.pagina = 1;
};

const ordenarPorFechaCita =
  (orden: "ASC" | "DESC" = "ASC") =>
  (r1: Respuesta, r2: Respuesta) => {
    try {
      const propHora = r1.time
        ? "time"
        : r1.time_1
          ? "time_1"
          : "time_truncated";
      const propFecha = r1.time || r1.time_truncated ? "date" : "date_1";
      const formato = r1[propHora].includes("M")
        ? "d 'de' MMMM h:m a"
        : "d 'de' MMMM H:m";
      const fecha1 = parse(
        `${r1[propFecha]} ${r1[propHora]}`,
        formato,
        new Date(),
        { locale: es },
      );
      const fecha2 = parse(
        `${r2[propFecha]} ${r2[propHora]}`,
        formato,
        new Date(),
        { locale: es },
      );
      return orden === "ASC"
        ? fecha1 > fecha2
          ? 1
          : -1
        : fecha1 < fecha2
          ? 1
          : -1;
    } catch (e) {
      captureException(e);
      return 1;
    }
  };

const funcionDeOrdenamiento = (
  header: string,
  orden: "ASC" | "DESC" = "ASC",
) => {
  if (header.includes("time") || header.includes("date")) {
    return ordenarPorFechaCita(orden);
  }
  return orden === "ASC"
    ? (r1: Respuesta, r2: Respuesta) =>
        r1.respuestaNormalizada[header] < r2.respuestaNormalizada[header]
          ? -1
          : 1
    : (r1: Respuesta, r2: Respuesta) =>
        r1.respuestaNormalizada[header] > r2.respuestaNormalizada[header]
          ? -1
          : 1;
};

export type GlobalFilter = {
  headers: "*";
  busqueda: string;
  busquedaNormalizada: string;
  oculto?: undefined;
};

export type ColumnFilter = {
  header: string;
  busqueda: string;
  busquedaNormalizada: string;
  exactMatch: boolean;
  nombreHeader: string;
  oculto?: boolean;
};

export interface RespuestasState {
  abrirAppWhatsapp: boolean;

  scrollTabla: number;

  respuestas?: Respuesta[];
  filtros: (GlobalFilter | ColumnFilter[])[];
  pagina: number;
  fechaInicio: number;
  fechaTermino: number;
  busqueda: string;
  nombreEncuestaFiltrada?: string;
  indiceRespuestaSeleccionada?: number;
  ordenHeader?: string;
  orden: "ASC" | "DESC";

  columnaDestacadaFija: boolean;
  columnaDestacada?: number;

  tablaDestacada: boolean;
}

const sliceRespuestas = createSlice({
  name: "respuestas",
  initialState: {
    fechaInicio: Date.now(),
    fechaTermino: Date.now(),
    busqueda: "",
    orden: "ASC",
    pagina: 1,
    filtros: [],
    columnaDestacada: undefined,
    columnaDestacadaFija: false,
    tablaDestacada: false,
    nombreEncuestaFiltrada: undefined,
    scrollTabla: 0,

    abrirAppWhatsapp: false,
  } as RespuestasState,
  reducers: {
    fijaAbrirAppWhatsapp(state, action: PayloadAction<boolean>) {
      state.abrirAppWhatsapp = action.payload;
    },
    fijaScrollTabla(state, action: PayloadAction<number>) {
      state.scrollTabla = action.payload;
    },
    limpiaRespuestas(state) {
      state.respuestas = undefined;
      state.indiceRespuestaSeleccionada = undefined;
    },
    guardaRespuestas(state, action: PayloadAction<GetAnswersResponse>) {
      const response = action.payload;
      const respuestas: Respuesta[] = response.answers
        .filter((r) => r.started)
        .map(({ notes, ...r }) => {
          const serializedNotes = notes.map(({ createdAt, ...note }) => ({
            createdAt: createdAt.getTime(),
            ...note,
          }));
          const canal = !r.isUnreachable.whatsapp
            ? { icon: "whatsapp", label: "Whatsapp" }
            : !r.isUnreachable.phone
              ? { icon: "phone", label: "Teléfono" }
              : { icon: "cellphone-off", label: "No pudo ser contactado" };

          /** A concatenation of normalized string values and tag IDs/names from r. */
          const respuestaString = Object.values(r).reduce<string>((prev, v) => {
            let slug = "";
            if (typeof v === "string") {
              slug = normalizar(v);
            } else if (v?.tag) {
              slug = normalizar(
                getTags(v.tag)
                  .map((v) => v.id)
                  .join() || v.tag,
              );
            }
            return prev + slug;
          }, "");
          /** A mapping of keys to normalized string values, tag IDs/names and icon labels from r. */
          const respuestaNormalizada = Object.entries(r).reduce<{
            [k: string]: string;
          }>(
            (prev, [k, v]) => {
              if (typeof v === "string") {
                prev[k] = normalizar(v);
              } else if (typeof v === "number") {
                prev[k] = normalizar(String(v));
              } else if (v?.tag || v?.tag === "") {
                prev[k] = normalizar(
                  getTags(v.tag)
                    .map((v) => v.id)
                    .join() || v.tag,
                );
              }
              return prev;
            },
            { [CANAL_HEADER_NAME]: normalizar(canal.label) },
          );

          return {
            ...r,
            [CANAL_HEADER_NAME]: canal,
            notes: serializedNotes,
            respuestaString,
            respuestaNormalizada,
          };
        })
        .reverse();

      state.respuestas = respuestas;
      resetPagination(state);
    },
    guardaRangoFechas(state, action: PayloadAction<[number, number]>) {
      const [fechaInicio, fechaTermino] = action.payload;
      state.fechaInicio = fechaInicio;
      state.fechaTermino = fechaTermino;
    },
    buscaEsto(state, action: PayloadAction<string>) {
      const busquedaNormalizada = normalizar(action.payload);
      state.busqueda = action.payload;
      const indiceFiltroGlobal = state.filtros.findIndex(
        (f) => !Array.isArray(f),
      );
      const filtro = {
        headers: "*",
        busqueda: action.payload,
        busquedaNormalizada,
      } satisfies GlobalFilter;
      if (indiceFiltroGlobal >= 0) {
        if (busquedaNormalizada.length > 0) {
          state.filtros[indiceFiltroGlobal] = filtro;
        } else {
          state.filtros.splice(indiceFiltroGlobal, 1);
        }
      } else if (busquedaNormalizada.length > 0) {
        state.filtros.push(filtro);
      }
      resetPagination(state);
    },
    toggleFiltro(
      state,
      action: PayloadAction<{
        busqueda: string;
        nombreHeader: string;
        textoHeader: string;
        opciones?: {
          filtroImplicito?: boolean;
          calceExacto?: boolean;
          mismaColumna?: boolean;
          titulo?: string;
        };
      }>,
    ) {
      const { busqueda, nombreHeader, textoHeader, opciones } = action.payload;
      const {
        filtroImplicito,
        calceExacto = false,
        titulo,
        mismaColumna,
      } = opciones || {};
      const busquedaNormalizada = normalizar(busqueda);
      const filtro = {
        header: nombreHeader,
        nombreHeader: textoHeader,
        busqueda,
        busquedaNormalizada,
        exactMatch: calceExacto,
        oculto: filtroImplicito,
      } satisfies ColumnFilter;
      const indiceFiltro = state.filtros.findIndex(
        (f) => Array.isArray(f) && f.every((h) => h.header === nombreHeader),
      );
      if (indiceFiltro >= 0) {
        const filtroExistente = state.filtros[indiceFiltro] as ColumnFilter[];
        if (mismaColumna) {
          const indiceTerminoExistente = filtroExistente.findIndex(
            (t) => t.busqueda === busqueda,
          );
          if (indiceTerminoExistente < 0) {
            state.filtros[indiceFiltro] = [...filtroExistente, filtro];
          } else if (
            filtroExistente.length === 1 &&
            filtroExistente[0].busqueda === busqueda
          ) {
            state.filtros.splice(indiceFiltro, 1);
          } else {
            filtroExistente.splice(indiceTerminoExistente, 1);
          }
        } else if (
          busquedaNormalizada.length > 0 &&
          filtroExistente[0].busqueda !== busqueda
        ) {
          state.filtros[indiceFiltro] = [filtro];
        } else if (!filtroImplicito) {
          state.filtros.splice(indiceFiltro, 1);
        }
      } else if (busqueda !== "") {
        state.filtros.push([filtro]);
      }
      if (filtroImplicito) {
        state.nombreEncuestaFiltrada = titulo;
      }
      resetPagination(state);
    },
    combinaFiltros(state, action: PayloadAction<[number, number]>) {
      const [i, j] = action.payload;
      if (i === j) {
        return;
      }

      const filtroi = state.filtros[i];
      const filtroj = state.filtros[j];

      if (!Array.isArray(filtroi) || !Array.isArray(filtroj)) {
        // We only allow joining column filters for now.
        return;
      }

      filtroj.push(...filtroi);
      state.filtros.splice(i, 1);
      resetPagination(state);
    },
    remueveFiltro(state, action: PayloadAction<number>) {
      const indiceFiltro = action.payload;
      const indiceFiltroGlobal = state.filtros.findIndex(
        (f) => !Array.isArray(f),
      );
      if (indiceFiltro === indiceFiltroGlobal) {
        state.busqueda = "";
      }
      state.filtros.splice(indiceFiltro, 1);
      resetPagination(state);
    },
    limpiaFiltros(state) {
      state.filtros = [];
      state.indiceRespuestaSeleccionada = undefined;
      state.nombreEncuestaFiltrada = undefined;
      state.busqueda = "";
    },
    guardaIdRespuesta(state, action: PayloadAction<number>) {
      const indice = action.payload;
      state.indiceRespuestaSeleccionada = indice;
    },
    ordenaRespuestas(
      state,
      action: PayloadAction<{ header: string; idEncuesta: number }>,
    ) {
      const { header, idEncuesta } = action.payload;
      state.ordenHeader = header;
      const tagCalculado = obtenerTagsCalculados(idEncuesta)?.find(
        (t) => t.nombre === header,
      );

      if (state.orden === "ASC") {
        state.orden = "DESC";
      } else {
        state.orden = "ASC";
      }

      let sortFn: (a: Respuesta, b: Respuesta) => number;
      if (tagCalculado) {
        const getTag = (r: Respuesta) =>
          normalizar(tagCalculado.f(r)?.tag ?? "");
        if (state.orden === "ASC") {
          sortFn = (r1, r2) => (getTag(r1) < getTag(r2) ? -1 : 1);
        } else {
          sortFn = (r1, r2) => (getTag(r1) > getTag(r2) ? -1 : 1);
        }
      } else {
        sortFn = funcionDeOrdenamiento(header, state.orden);
      }

      state.respuestas?.sort(sortFn);
      state.indiceRespuestaSeleccionada = undefined;
    },
    setPagina(state, action: PayloadAction<number>) {
      state.pagina = action.payload;
    },
    destacaColumna(state, action: PayloadAction<number>) {
      if (!state.columnaDestacadaFija) {
        state.columnaDestacada = action.payload;
      }
    },
    yaNoDestaquesColumna(state) {
      if (!state.columnaDestacadaFija) {
        state.columnaDestacada = undefined;
      }
    },
    fijaColumna(state, action: PayloadAction<boolean>) {
      state.columnaDestacadaFija = action.payload;
      if (!action.payload) {
        state.columnaDestacada = undefined;
      }
    },
    fijaTablaDestacada(state, action: PayloadAction<boolean>) {
      state.tablaDestacada = action.payload;
    },
    agregaReaccionARespuesta(
      state,
      action: PayloadAction<{
        userId: number;
        emoji: string;
        text: string;
      }>,
    ) {
      if (!state.respuestas) {
        return;
      }

      const { userId, emoji, text } = action.payload;
      // FIXME: this createdAt value will not be correct due to latencies between server and client.
      const serializedNote = {
        createdAt: Date.now(),
        emoji,
        text,
      };
      const matchesUser = (r: Respuesta) => r.userId === userId;

      const respuesta = state.respuestas.find(matchesUser);
      respuesta?.notes.push(serializedNote);
    },
    eliminaReaccionDeRespuesta(
      state,
      action: PayloadAction<{ userId: number; createdAt: number }>,
    ) {
      if (!state.respuestas) {
        return;
      }

      const { userId, createdAt } = action.payload;
      const matchesUser = (r: Respuesta) => r.userId === userId;
      const matchesDate = (note: Respuesta["notes"][0]) =>
        note.createdAt === createdAt;

      const respuesta = state.respuestas.find(matchesUser);
      if (respuesta) {
        const reactionIndex = respuesta.notes.findIndex(matchesDate);
        respuesta.notes.splice(reactionIndex, reactionIndex);
      }
    },
  },
});

export const {
  limpiaRespuestas,
  guardaRespuestas,
  guardaRangoFechas,
  buscaEsto,
  guardaIdRespuesta,
  ordenaRespuestas,
  setPagina,
  toggleFiltro,
  remueveFiltro,
  combinaFiltros,
  destacaColumna,
  yaNoDestaquesColumna,
  fijaTablaDestacada,
  fijaColumna,
  limpiaFiltros,
  agregaReaccionARespuesta,
  eliminaReaccionDeRespuesta,
  fijaScrollTabla,
  fijaAbrirAppWhatsapp,
} = sliceRespuestas.actions;
export const respuestasSelector = (state: RootState) => state.respuestas;

export default sliceRespuestas.reducer;
