import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Integration } from "api/client/models/Integration";
import { SuccessResponse } from "api/client/models/SuccessResponse";

import { RootState } from ".";

export const CANAL_HEADER_NAME = "canal";

export type TipoEncuesta = {
  id: number;
  nombre: string;
  enabled: boolean;
  integrations: Integration[];
  sucursales: string[];
};

export interface EncuestasState {
  idEncuestaSeleccionada?: number;
  tipos?: TipoEncuesta[];
}

const encuestasSlice = createSlice({
  name: "encuestas",
  initialState: {} as EncuestasState,
  reducers: {
    guardaTiposEncuestas(state, action: PayloadAction<SuccessResponse>) {
      const tiposOrdenados = action.payload.polls.sort((x, y) =>
        x.name.localeCompare(y.name),
      );
      state.tipos = tiposOrdenados.map(
        ({ id, name, enabled, integrations, sucursales }) => ({
          id,
          nombre: name,
          enabled,
          integrations,
          sucursales: sucursales.map((s) => s.name),
        }),
      );
    },
    guardaIdEncuestaSeleccionada(state, action: PayloadAction<number>) {
      state.idEncuestaSeleccionada = action.payload;
    },
    limpiaEncuestas(state) {
      state.idEncuestaSeleccionada = undefined;
      state.tipos = undefined;
    },
  },
});

export const {
  guardaTiposEncuestas,
  guardaIdEncuestaSeleccionada,
  limpiaEncuestas,
} = encuestasSlice.actions;
export const encuestasSelector = (state: RootState) => state.encuestas;

export default encuestasSlice.reducer;
