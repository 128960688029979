import { AnswerHeader, ComputedHeader, Respuesta } from "api/types/domain";

import {
  YES,
  NO,
  REAGENDA,
  ACTION_SUCCESS,
  AGENDA_OPCION_1,
  AGENDA_OPCION_2,
  AGENDA_OPCION_3,
  AGENDA_OPCION_4,
  AGENDA_OPCION_5,
} from "./tags";

export const obtenerTagsCalculados = (
  idEncuesta: number,
): ComputedHeader[] | undefined => {
  return (() => {
    const fechaCambioMapping = "2022-03-18";
    switch (idEncuesta) {
      case 635: // lista de espera hbv
        return [
          {
            texto: "Confirma?",
            tipo: "YESNO",
            f: (r: Respuesta) => r[0],
          },
          {
            texto: "¿Se arrepiente?",
            tipo: "OPEN",
            f: (r: Respuesta) =>
              r[2].tag === "NO_CANCELAR"
                ? {
                    tag: "DEFAULT",
                    text: r[2].text,
                  }
                : { tag: "", text: "" },
          },
        ] as const;
      case 509: // SS agendamiento dental
      case 557: // agendamiento norden
      case 577: // agendamiento everest
        // Uses templates/poll-agendamiento-odontopediatria.xml.jinja
        return [
          {
            texto: "Encontramos horas",
            tipo: "INTERNAL",
            f: (r: Respuesta) => r[3],
          },
          {
            texto: "Opción elegida",
            tipo: "OPEN",
            f: (r: Respuesta) => r[4],
          },
          {
            texto: "Bloque agendado",
            tipo: "INTERNAL",
            f: (r: Respuesta) => {
              if (r[61]?.tag === ACTION_SUCCESS) {
                return {
                  tag: AGENDA_OPCION_1,
                  text: "Bloque 1",
                };
              }
              if (r[62]?.tag === ACTION_SUCCESS) {
                return {
                  tag: AGENDA_OPCION_2,
                  text: "Bloque 2",
                };
              }
              if (r[63]?.tag === ACTION_SUCCESS) {
                return {
                  tag: AGENDA_OPCION_3,
                  text: "Bloque 3",
                };
              }
              if (r[64]?.tag === ACTION_SUCCESS) {
                return {
                  tag: AGENDA_OPCION_4,
                  text: "Bloque 4",
                };
              }
              if (r[65]?.tag === ACTION_SUCCESS) {
                return {
                  tag: AGENDA_OPCION_5,
                  text: "Bloque 5",
                };
              }
              return { tag: "", text: "" };
            },
          },
        ] as const;
      case 213: // Sanasalud CMSC:
        return [
          {
            texto: "Respuesta",
            tipo: "YESNO",
            f: (r: Respuesta) => {
              if (r[7]?.tag) {
                return { tag: NO, text: "Usuario cancela post interacción" };
              }
              if (
                [r[11]?.tag, r[12]?.tag, r[13]?.tag].includes(ACTION_SUCCESS)
              ) {
                return { tag: REAGENDA, text: "Reagendamiento exitoso" };
              }
              if (r[2]?.tag === REAGENDA || r[2]?.tag === YES) {
                return { tag: REAGENDA, text: "Reagendamiento en curso" };
              }
              return r[0];
            },
          },
        ] as const;
      case 453: // FALP convenios -- archived
        return [
          {
            texto: "¿Dirección?",
            tipo: "YESNO",
            f: (r: Respuesta) => (r.start < fechaCambioMapping ? r[0] : r[0]),
          },
          {
            texto: "Pedir dirección correcta",
            tipo: "OPEN",
            f: (r: Respuesta) => (r.start < fechaCambioMapping ? r[10] : r[10]),
          },
          {
            texto: "¿Email?",
            tipo: "OPEN",
            f: (r: Respuesta) => (r.start < fechaCambioMapping ? r[1] : r[2]),
          },
          {
            texto: "Pedir email correcto",
            tipo: "OPEN",
            f: (r: Respuesta) => (r.start < fechaCambioMapping ? r[11] : r[12]),
          },
          {
            texto: "¿Prevision?",
            tipo: "YESNO",
            f: (r: Respuesta) => (r.start < fechaCambioMapping ? r[2] : r[3]),
          },
          {
            texto: "Pedir previsión correcta",
            tipo: "OPEN",
            f: (r: Respuesta) => (r.start < fechaCambioMapping ? r[12] : r[13]),
          },
        ] as const;
      case 255: // Sanasalud Kopland T-5 -- archived
        return [
          {
            texto: "¿Confirma?",
            tipo: "YESNO",
            f: (r: Respuesta) => r[0],
          },
          {
            texto: "Reagenda",
            tipo: "YESNO",
            f: (r: Respuesta) => r[1],
          },
          {
            texto: "¿Por qué no?",
            tipo: "OPEN",
            f: (r: Respuesta) => r[-15],
          },
          {
            texto: "¿Por qué no?",
            tipo: "OPEN",
            f: (r: Respuesta) => r[-16],
          },
        ] as const;
    }
  })()?.map((tag, i) => ({ ...tag, nombre: `tc${i}` }));
};

export const obtenerHeadersConTagsCalculados = (
  headers: AnswerHeader[],
  idEncuesta: number,
): (ComputedHeader | AnswerHeader)[] | undefined => {
  let tagsCalculados: (ComputedHeader | AnswerHeader)[] = headers.filter(
    (e) => e.tipo === "COMPUTED",
  );
  // Get computed headers from legacy method
  // TODO: this should be changed to the new spec and removed
  if (tagsCalculados.length === 0) {
    tagsCalculados = obtenerTagsCalculados(idEncuesta) || [];
  }
  if (tagsCalculados.length === 0) {
    return undefined;
  }
  const headersSinTags = headers
    .filter(
      (h) =>
        !["YESNO", "RANGE", "OPEN", "INTERNAL", "COMPUTED"].includes(h.tipo),
    )
    .map((h) =>
      h.texto.includes(" Externo") ? { ...h, texto: h.texto.slice(0, -8) } : h,
    );
  return [...tagsCalculados, ...headersSinTags];
};
