import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";

import { MetricFilter } from "api/client/models/MetricFilter";
import { MetricsTimeSeriesTimeUnit } from "api/hooks/dashboards/useMetricsTimeSeriesQuery";
import { localeCompare } from "utils/strings";

import { RootState } from ".";

type DashboardFilter = {
  propertyId: MetricFilter["id"];
  value: string;
};

interface DashboardState {
  start: number;
  end: number;
  includeSaturdays: boolean;
  includeSundays: boolean;
  timeUnit: MetricsTimeSeriesTimeUnit;
  filters: DashboardFilter[];
}

const Dashboard = createSlice({
  name: "Dashboard",
  initialState: {
    start: +startOfWeek(addWeeks(new Date(), -4), { weekStartsOn: 1 }),
    end: +endOfWeek(addWeeks(new Date(), -1), { weekStartsOn: 1 }),
    includeSaturdays: true,
    includeSundays: true,
    timeUnit: "WEEK",
    filters: [],
  } as DashboardState,
  reducers: {
    setStartDate(state, action: PayloadAction<number>) {
      state.start = action.payload;
    },
    setEndDate(state, action: PayloadAction<number>) {
      state.end = action.payload;
    },
    setIncludeWeekends(state, action: PayloadAction<boolean>) {
      state.includeSaturdays = action.payload;
      state.includeSundays = action.payload;
    },
    setIncludeSaturdays(state, action: PayloadAction<boolean>) {
      state.includeSaturdays = action.payload;
    },
    setIncludeSundays(state, action: PayloadAction<boolean>) {
      state.includeSundays = action.payload;
    },
    setGroupByUnit(state, action: PayloadAction<MetricsTimeSeriesTimeUnit>) {
      const timeUnit = action.payload;
      state.timeUnit = timeUnit;
      if (timeUnit === "MONTH") {
        if (isSameMonth(state.start, state.end)) {
          // Show at least 2 data points
          state.start = +addMonths(state.start, -1);
        }
        state.start = +startOfMonth(state.start);
        state.end = +endOfMonth(state.end);
      } else if (timeUnit === "WEEK") {
        if (isSameWeek(state.start, state.end, { weekStartsOn: 1 })) {
          // Show at least 2 data points
          state.start = +addWeeks(state.start, -1);
        }
        state.start = +startOfWeek(state.start, { weekStartsOn: 1 });
        state.end = +endOfWeek(state.end, { weekStartsOn: 1 });
      }
    },
    addFilter(
      state,
      action: PayloadAction<{
        propertyId: MetricFilter["id"];
        value: string;
      }>,
    ) {
      const { propertyId, value } = action.payload;
      state.filters.push({
        propertyId,
        value,
      });
      state.filters = state.filters.toSorted((a, b) =>
        localeCompare(a.value, b.value),
      );
    },
    removeFilter(
      state,
      action: PayloadAction<{
        propertyId: MetricFilter["id"];
        value: string;
      }>,
    ) {
      const { propertyId, value } = action.payload;
      state.filters = state.filters.filter(
        (f) => f.propertyId !== propertyId || f.value !== value,
      );
    },
  },
});

export const {
  setStartDate,
  setEndDate,
  setIncludeWeekends,
  setIncludeSaturdays,
  setIncludeSundays,
  setGroupByUnit,
  addFilter,
  removeFilter,
} = Dashboard.actions;
export const dashboardSelector = (state: RootState) => state.dashboard;

export default Dashboard.reducer;
