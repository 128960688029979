import { captureException } from "@sentry/react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import "assets/fonts/fonts.css";
import App from "components/App";
import store from "store";

import "./colores.css";
import "./instrument";
import "./reset.css";

const container = document.getElementById("root")!;
const root = createRoot(container);
const queryClient = new QueryClient();

const dynamicImportErrors = [
  "error loading dynamically imported module:",
  "Failed to fetch dynamically imported module:",
  "Unable to preload CSS for",
];

window.addEventListener("vite:preloadError", async (event) => {
  if (
    dynamicImportErrors.some((errorPrefix) =>
      event.payload.message.startsWith(errorPrefix),
    )
  ) {
    await new Promise((res) => setTimeout(res, 0));
    window.location.reload();
  } else {
    captureException(event.payload);
  }
});

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </StrictMode>,
);
